import Button from 'components/Button';
import Div from 'components/Div';
import Form from 'components/Form';
import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { AiOutlineSearch, AiFillEdit, AiOutlineEye, AiOutlineCheck } from 'react-icons/ai';
import axios from 'axios';
import { getReferenceContext } from 'contexts/reference';
import Table from 'components/Table';
import { tableComplaint } from 'utils/tableModels';
import { NotificationManager } from 'react-notifications';
import Dialog from 'components/Dialog';
import { popupDetailStyle, popupFormStyle } from 'utils/sharedStyles';
import { formComplaint, formCreateComplaint, formSolveComplaint } from 'utils/formModels';
import ComplaintCategory from './ComplaintCategory';
import Tag from 'components/Tag';
import SwitchButton from 'components/SwitchButton';
import { getOrderContext } from 'contexts/order';
import { getAuthContext } from 'contexts/auth';
import Req from 'utils/Request';
import { getSocketContext } from 'contexts/socket';
import { timeSince } from 'utils/helpers';

const Complaint = () => {
    const { setIsLoading } = React.useContext(getReferenceContext);
    const { socket } = React.useContext(getSocketContext);
    const { custPhone } = React.useContext(getOrderContext);
    const [data, setData] = React.useState(null);
    const [filters, setFilters] = React.useState({ custPhone: custPhone ?? '' });
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [formDisabled, setFormDisabled] = React.useState(true);
    const [categoryObject, setCategoryObject] = React.useState(null);
    const formRef = React.useRef();
    const formSolveComplaintRef = React.useRef();
    const [paginationSetting, setPaginationSetting] = React.useState({ limit: 10, offset: 0 });
    const switchCases = ['Шийдвэрлээгүй', 'Шийдвэрлэсэн'];
    const [isSolved, setIsSolved] = React.useState(switchCases[0]);
    const [solvingInstance, setSolvingInstance] = React.useState(null);
    const { user } = React.useContext(getAuthContext);
    const fetchData = async () => {
        const body = {};
        const { startDate, endDate, custPhone, store } = filters;
        if (startDate || endDate || custPhone || store) {
            if (startDate && endDate && startDate.getTime() > endDate.getTime()) return NotificationManager.warning('Эхлэх огноо дуусах огнооноос их байх боломжгүй', '', 3000);
            if (startDate) body.startDate = startDate;
            if (endDate) body.endDate = endDate;
            if (custPhone) body.custPhone = custPhone;
            if (store) body.store = store;
        }
        const bodyObject = {
            ...body,
            ...paginationSetting,
            sortBy: 'createdAt',
            sortDirection: 'DESC',
            isSolved: isSolved === 'Шийдвэрлэсэн' ? true : false,
        };
        const result = await Req({ url: '/complaint/list', body: bodyObject, hideSuccess: true });
        setData(result.data.data.complaint);
    };
    // React.useEffect(() => {
    //     const filtered = roles.filter((x) => x === 'INFO');
    //     if (filtered[0]) setManager(filtered[0]);
    // }, [roles]);
    const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === 'notifyComplaint') {
            fetchData();
        }
    };
    React.useEffect(() => {
        fetchData();
    }, [paginationSetting, isSolved]);
    React.useEffect(() => {
        if (socket) {
            socket.addEventListener('message', handleMessage);
            return () => {
                socket.removeEventListener('message', handleMessage);
            };
        }
    }, [socket]);
    const handleSave = async (e) => {
        e?.preventDefault();
        if (!selectedInstance.store) return NotificationManager.warning('Салбар сонгоно уу.', '', 3000);
        if (!categoryObject) return NotificationManager.warning('Гомдлын төрөл сонгоно уу.', '', 3000);
        const body = { ...selectedInstance, ...categoryObject, custPhone: custPhone };
        await Req({ url: '/complaint/create', body });
        fetchData();
        setSelectedInstance(null);
    };
    const handleSolveComplaint = async (e) => {
        e?.preventDefault();
        const body = { resolvedNote: solvingInstance.resolvedNote, resolvedEmp: user.username, isSolved: true, id: solvingInstance.id };
        await Req({ url: '/complaint/resolve', body });
        fetchData();
        setSolvingInstance(null);
    };
    return (
        <Container>
            <Title size={20} style={{ marginBottom: 50 }}>
                Гомдол
            </Title>
            <SwitchButton selected={isSolved} onChange={setIsSolved} options={switchCases} />
            <Div c="filter_wrap">
                <Form
                    listenChange={(e) => setFilters({ ...e })}
                    onSubmit={(e) => e.preventDefault()}
                    styles={filterFormStyle}
                    instance={filters}
                    model={{
                        startDate: { label: 'Эхлэх огноо', type: 'SelectDatetime' },
                        endDate: { label: 'Дуусах огноо', type: 'SelectDatetime' },
                        custPhone: { label: 'Утасны дугаар', type: 'Text' },
                        store: { label: 'Салбар сонгох', type: 'SelectStore', byRole: true },
                    }}
                />
                <Button bg="blue" onClick={fetchData}>
                    <AiOutlineSearch />
                    Хайх
                </Button>
                <Button
                    bg="green"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                        setSelectedInstance({ operator: user.username });
                        setFormDisabled(false);
                    }}
                >
                    + Гомдол бүртгэх
                </Button>
            </Div>
            <Table
                data={data}
                paginationSetting={paginationSetting}
                setPaginationSetting={setPaginationSetting}
                model={{
                    custom2: {
                        label: 'Үүсгэсэн',
                        jsx: ({ thisInstance }) => <Tag color="green">{timeSince(thisInstance.createdAt)}-ийн өмнө</Tag>,
                    },
                    ...tableComplaint,
                    custom: {
                        label: '',
                        sticky: true,
                        jsx: ({ thisInstance }) => (
                            <Div style={{ display: 'flex', gap: 15 }}>
                                <Button
                                    bg="green"
                                    onClick={() => {
                                        setSelectedInstance(thisInstance);
                                        setFormDisabled(true);
                                    }}
                                >
                                    <AiOutlineEye />
                                    Үзэх
                                </Button>
                                {isSolved === 'Шийдвэрлээгүй' && (
                                    <Button
                                        bg="blue"
                                        onClick={() => {
                                            setSolvingInstance(thisInstance);
                                            setFormDisabled(false);
                                        }}
                                    >
                                        <AiFillEdit />
                                        Шийдвэрлэх
                                    </Button>
                                )}
                            </Div>
                        ),
                    },
                    isSolved: {
                        label: 'Төлөв',
                        jsx: ({ thisInstance }) => <Tag color={thisInstance.isSolved ? 'green' : 'blue'}>{thisInstance.isSolved ? 'Шийдвэрлэгдсэн' : 'Бүртгэгдсэн'}</Tag>,
                    },
                }}
            />
            {selectedInstance && (
                <Dialog
                    onClose={() => setSelectedInstance(null)}
                    title="Гомдлын дэлгэрэнгүй"
                    submit={
                        formDisabled ? null : (
                            <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">
                                Хадгалах
                            </Button>
                        )
                    }
                    closeButtonProps={{ bg: 'blue', color: 'white' }}
                >
                    {formDisabled ? (
                        <Form forwardRef={formRef} model={formComplaint} instance={selectedInstance} styles={popupDetailStyle} disabled={formDisabled} staticType />
                    ) : (
                        <Div c="create_complaint_wrap">
                            <ComplaintCategory onChange={setCategoryObject} />
                            <Form
                                listenChange={setSelectedInstance}
                                onSubmit={handleSave}
                                forwardRef={formRef}
                                model={formCreateComplaint}
                                instance={selectedInstance}
                                styles={createComplaintStyle}
                                disabled={formDisabled}
                            />
                        </Div>
                    )}
                </Dialog>
            )}
            {solvingInstance && (
                <Dialog
                    onClose={() => setSolvingInstance(null)}
                    title="Гомдол шийдвэрлэх"
                    submit={
                        formDisabled ? null : (
                            <Button onClick={() => formSolveComplaintRef.current.querySelector('.submit').click()} bg="blue" type="submit">
                                Шийдвэрлэх
                            </Button>
                        )
                    }
                    width="500px"
                >
                    <Form
                        forwardRef={formSolveComplaintRef}
                        model={formSolveComplaint}
                        instance={solvingInstance}
                        styles={popupFormStyle}
                        listenChange={setSolvingInstance}
                        onSubmit={handleSolveComplaint}
                    />
                </Dialog>
            )}
        </Container>
    );
};

export default Complaint;

const Container = styled.div`
    .filter_wrap {
        margin-top: 30px;
        display: flex;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 30px;
    }
    .create_complaint_wrap {
        display: flex;
        & > form {
            flex: 1;
            min-width: 500px;
        }
    }
`;

const filterFormStyle = `
    display:flex;
    gap:15px;
    & > div {
        display:flex;
        flex-direction:column-reverse;
        &:nth-child(3){
            flex:3;
        }
        & > * {
            &:first-child{
                opacity:0.6;
                margin-top:6px;
                display:block;
                font-size:10px;
            }
            &:last-child{
            }
        }
    }
`;

const createComplaintStyle = `
    & > div {
        margin-bottom:15px;
        & > * {
            &:first-child{
                display:block;
                margin-bottom:8px;
            }
            &:last-child{
            }
        }
    }
`;
