export const resolveFoodLabel = (string) => {
    let label
    switch (string) {
        case 'Side': label = 'Нэмэлт'; break
        case 'Pizza': label = 'Пицца'; break
        case 'Meal': label = 'Хоол'; break
        case 'Dessert': label = 'Дессерт'; break
        case 'Combo': label = 'Багц'; break
        case 'Beverage': label = 'Ундаа'; break
        case 'Deal': label = 'Урамшуулал'; break
    }
    return label
}
export const resolveWeekLabel = (string) => {
    let label
    switch (string) {
        case 1: label = 'Даваа'; break
        case 2: label = 'Мягмар'; break
        case 3: label = 'Лхагва'; break
        case 4: label = 'Пүрэв'; break
        case 5: label = 'Баасан'; break
        case 6: label = 'Бямба'; break
        case 7: label = 'Ням'; break
    }
    return label
}
export const timeSince = (date) => {
    date = new Date(date)
    date = date.getTime()
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " жил";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " сар";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " өдөр";
    }
    interval = seconds / 3600;
    if (interval > 2) {
        return Math.floor(interval) + " цаг";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " минут";
    }
    return Math.floor(seconds) + " сек";
}

const errorDetail = (err) => {
    return new Promise((resolve, reject) => {
        if (err.isAxiosError) {
            if (err.response) {
                // Server was able to send us a response, so this is an API Error.
                console.error('[API Error]:', err.response.data.errorMessage);
                resolve(err.response.data.errorMessage ?? err.response.data.error)
            } else {
                // Axios was not able to get a response at all. This is a Network-Level Error.
                console.error('[Network Error]: No Response Received At', err);
                resolve('Та интернет холболтоо шалгана уу')
            }
        } else {
            console.error("[Non-HTTP Error]:", err);
            resolve(err.message)
        }
    })
};

export default errorDetail;