import React, { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import notifAudio from '../utils/notification.wav';
import configJson from '../config.json';
import { getAuthContext } from './auth';

export const getSocketContext = React.createContext();

export const SocketContextProvider = ({ children, ...props }) => {
    const { jwtToken } = React.useContext(getAuthContext);
    const socketRef = useRef(null);
    const reconnectDelay = 1000; // Reconnection delay in ms
    const heartbeatInterval = 30000; // Heartbeat interval in ms
    // const [token, setToken] = React.useState('');
    let url = `${configJson.url.aws_socket}?accessToken=${jwtToken}`;
    // if (jwtToken) {
    //     url = `${configJson.url.aws_socket}?accessToken=${jwtToken}`;
    // }
    // let socket = new WebSocket(url);
    useEffect(() => {

        let reconnectAttempts = 0;
        let heartbeat;

        const connect = () => {
            if (!jwtToken) return;
            socketRef.current = new WebSocket(url);
            socketRef.current.onopen = (event) => {
                console.log('Connected to WebSocket');
                reconnectAttempts = 0;
                // Start sending heartbeat messages
                heartbeat = setInterval(() => {
                    if (socketRef.current.readyState === WebSocket.OPEN) {
                        socketRef.current.send(JSON.stringify({ type: 'ping', timestamp: Date.now() }));
                    }
                }, heartbeatInterval);
            };

            socketRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.action === 'notifyComplaint') {
                    NotificationManager.info('Шинэ гомдол орж ирлээ.', '', 3000);
                    playNotifWav();
                }
                if (data.action === 'notifyToConnection') {
                    NotificationManager.info('Шинэ цуцлах хүсэлт орж ирлээ.', '', 3000);
                    playNotifWav();
                }
                // setMessages((prevMessages) => [...prevMessages, data]);
                console.log('Received:', data);
            };

            socketRef.current.onclose = () => {
                console.log('WebSocket closed. Reconnecting...');
                clearInterval(heartbeat);
                if (reconnectAttempts < 5) {
                    setTimeout(connect, reconnectDelay);
                    reconnectAttempts++;
                } else {
                    console.error('Max reconnection attempts reached.');
                }
            };

            socketRef.current.onerror = (error) => {
                console.log('WebSocket error:',error);
                // connect();
            };
        };

        connect();

        return () => {
            clearInterval(heartbeat);
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [jwtToken]);
    // const getUserToken = async () => {
    //     const {
    //         accessToken: { jwtToken },
    //     } = await Auth.currentSession();
    //     setToken(jwtToken);
    // };
    const playNotifWav = () => {
        const audio = new Audio(notifAudio);
        audio.play();
    };
    const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === 'notifyComplaint') {
            NotificationManager.info('Шинэ гомдол орж ирлээ.', '', 3000);
            playNotifWav();
        }
    };
    // const pingDefault = () => {
    //     socket.send(JSON.stringify({ action: 'ping', data: 'Connection renewal' }));
    // };
    const renewConnection = async () => {
        // await getUserToken();
        // socket = new WebSocket(`${configJson.url.aws_socket}?accessToken=${jwtToken}`);
        // socket.send(JSON.stringify({ action: 'ping', data: 'Connection renewal' }));

        console.log('renewed connection');
    };
    // useEffect(() => {
    //     getUserToken();
    //     console.log(socket);
    //     if (socket) {
    //         console.log('asd');
    //         // socket.addEventListener('message', handleMessage);
    //         // socket.addEventListener('close', () => {
    //         //     console.log('connection lost');
    //         //     renewConnection();
    //         // });
    //         const interval = setInterval(pingDefault, 9 * 60 * 1000);
    //         return () => {
    //             socket.removeEventListener('message', handleMessage);
    //             clearInterval(interval);
    //         };
    //     }
    // }, [socket]);

    return (
        <getSocketContext.Provider
            value={
                {
                    // socket,
                    // getUserToken,
                }
            }
        >
            <audio src="../utils/notification.wav"></audio>
            {children}
        </getSocketContext.Provider>
    );
};
